import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Card, Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Home({ data }) {
  return (
    <Layout>
      <Container>
        <h1 className="mb-4">NUTFIELD Video Gallery ({data.allYoutubeVideo.totalCount})</h1>
      </Container>
      <Container>
        <Row>
          {data.allYoutubeVideo.edges.map(({ node }) => (
            <Col md={4} className="mb-4">
              <Link to={node.videoId} className="card-link">
                <Card bg="dark" className="card-hover">
                  <GatsbyImage className="card-img-top" image={node.localThumbnail.childImageSharp.gatsbyImageData} alt={node.title} />
                  <Card.Body className="p-2">
                    <Card.Title>{node.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}

        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allYoutubeVideo {
      totalCount
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt(formatString: "MMMM DD, YYYY")
          thumbnail {
            url
          }
          localThumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
